<template>
  <Skeleton v-if="loading" width="100" height="72" />
  <NavbarTransaction v-else>
    <router-link class="navbar-brand" :to="{ path: '/' }">
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.6666 9.66667V12.3333H5.66663L13 19.6667L11.1066 21.56L0.546631 11L11.1066 0.440002L13 2.33334L5.66663 9.66667H21.6666Z"
          fill="#F2F2F2"
        />
      </svg>
    </router-link>
    <h6 class="title-navbar">
      Intruksi Pembayaran
    </h6>
  </NavbarTransaction>

  <section class="section__donation-wrapper p-4">
    <div class="text-center detail__payment">
      <Skeleton v-if="loading" widht="50" height="25" style="margin-bottom:.5rem;" />
      <h5 v-else class="text-dark-grey text-title-donation">
        Intruksi Pembayaran
      </h5>
      <Skeleton v-if="loading" widht="100" height="18" style="margin-bottom:1rem;" />
      <div v-else>
        <p v-if="dataPayment.gate === 'midtrans'" class="text-dark-grey text-description-donation">
          Pembayaran menggunakan Gopay, <a href="javascript:void(0)" @click="handleOpenSnapPay(dataPayment.paymentToken)">Klik link berikut</a> jika
          halaman pembayaran tidak muncul
        </p>
        <p v-else class="text-dark-grey text-description-donation">
          Silahkan transfer ke nomor berikut ini sesuai metode pembayaran yang dipilih:
        </p>
      </div>

      <Skeleton
        v-if="loading"
        class="nominal"
        widht="215px"
        height="48"
        style="margin-bottom:.5rem;"
      />
      <div v-else class="nominal">
        <h1 class="mt-3 nominal-value">
          Rp. {{ total }}<span class="text-primary text-primary-donation">{{ uniqueCode }}</span>
        </h1>
        <div class="copied-value">
          <span @click="handleCopyValue(subTotal)">Salin</span>
          <div id="tooltip-value" class="tooltip">
            Tersalin
          </div>
        </div>
      </div>

      <Skeleton v-if="loading" widht="149px" height="18" style="margin-bottom:.5rem;" />
      <div v-else>
        <p v-if="dataPayment.gate == null" class="name-bank">
          ke rekening <b>{{ dataPayment.nameBank }}</b> - A/n {{ dataPayment.ownerName }}
        </p>
      </div>

      <Skeleton v-if="loading" widht="100" height="70" style="margin-bottom:1rem;" />

      <div v-else class="alert alert-warning alert-donation">
        <b>PENTING!</b> Mohon transfer tepat sampai 3 angka terakhir agar donasi anda lebih mudah
        diverifikasi
      </div>
    </div>

    <Skeleton v-if="loading" widht="100" height="74" style="margin-bottom:.5rem;" />
    <div v-else class="text-center w-100 h-100">
      <div
        class="method__payment-donation"
        v-if="dataPayment.gate === null || dataPayment.gate === 'tripay'"
      >
        <div class="icon__payment">
          <img :src="dataPayment.iconBank" alt="Icon Payment" class="icon__information"/>
        </div>
        <div class="name__payment">
          <b>{{ dataPayment.accountNumber }}</b>
          <p>{{ dataPayment.nameBank }}</p>
        </div>
        <div class="copy__payment">
          <span @click="handleTooltipCopy(dataPayment.accountNumber)">Salin</span>
          <div id="tooltip-payment" class="tooltip">
            Tersalin
          </div>
        </div>
      </div>

      <img
        v-if="dataPayment.qrisUrl !== null"
        :src="dataPayment.qrisUrl"
        alt="Qris"
        class="qrcode__payment"
      />
    </div>

    <Skeleton v-if="loading" widht="100" height="16" style="margin-bottom:1rem;" />

    <div v-else class="text-center mt-3">
      <p v-if="dataPayment.gate === null || dataPayment.gate === 'tripay'" class="enddate-donation">
        Silahkan melakukan pembayaran sebelum
        <b class="text-primary"> {{ dataPayment.transactionDueDate }} WIB </b>
        atau donasi akan otomatis dibatalkan oleh sistem kami.
      </p>
    </div>

    <Skeleton v-if="loading" widht="100" height="24" />
    <div v-else class="instruction__payment" v-show="dataPayment.gate === 'tripay' && dataPayment.qrisUrl == null">
      <h5 class="text-dark-grey font-weight-bold">
        Cara bayar
      </h5>
      <div v-for="(intruction, index) in instructionStep" :key="index" class="mb-2">
        <b>{{ intruction.title }}</b>
        <div class="step__payment">
          <div v-for="(step, key) in intruction.steps" :key="key" class="d-flex font-weight-500">
            <label for="number">{{ key + 1 }}.</label>
            <p class="ms-2 mb-0">
              {{ step }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <Skeleton v-if="loading" widht="100" height="70" style="margin-bottom:1rem;" />

    <div v-else class="alert alert-warning alert-donation" v-show="dataPayment.gate === null">
      <b>PENTING!</b> Setelah transfer, mohon untuk mengirim bukti pembayaran dengan klik tombol
      dibawah ini
    </div>

    <Skeleton v-if="loading" widht="100" height="70" style="margin-bottom:1rem;" />

    <div v-else class="donation__wrapper">
      <div class="group__donation-wrapper" v-if="dataPayment.gate === null || transactionStatus === 'waiting'">
        <button
          type="button"
          class="btn btn-warning btn-pay"
          :disabled="loadingVerifying"
          @click="handleVerifyingBankTransfer"
        >
          Saya Sudah Transfer
          <div v-if="loadingVerifying" class="spinner-border text-light" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import NavbarTransaction from '../components/TransactionComponent/NavbarTransaction.vue'
import Skeleton from '../components/Skeleton/Skeleton.vue'

import swal from 'sweetalert2'

export default {
  components: { NavbarTransaction, Skeleton },
  data() {
    return {
      total: null,
      subTotal: null,
      uniqueCode: null,
      dataPayment: {
        id: null,
        nameBank: null,
        iconBank: null,
        code: null,
        gate: null,
        accountNumber: null,
        transactionDueDate: null,
        qrisUrl: null,
        paymentToken: null
      },
      transactionStatus: '',
      tripayDetail: {
        namePayment: null,
        accountNumber: null,
        paymentLogo: null,
        paymentQrisUrl: null,
        transactionDueDate: null
      },
      instructionStep: [],
      loading: true,
      loadingVerifying: false
    }
  },
  mounted() {
    this.getDetailDonasi()
  },
  methods: {
    getDetailDonasi() {
      this.$http
        .get(`${process.env.VUE_APP_API_URL}donate/${this.$route.params.code}`)
        .then(response => {
          const data = response.data
          const formatPrice = this.$formatPrice(data.total)
          this.subTotal = formatPrice
          const total = formatPrice.split('.')[0]
          this.total = total + '.'
          this.uniqueCode = response.data.unique_number

          const paymentChannelId = data.payment_channel_id
          let instructionArry = []
          if (data.payment_gateway == 'tripay') {
            if (paymentChannelId === '13') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'BNI Virtual Account',
                iconBank: require('../assets/svg/icon-bni-bank.svg'),
                code: 'BNIVA',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                accountNumber: data.payment_code,
                transactionDueDate: this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL')
              }

              instructionArry = [
                {
                  title: 'Internet Banking',
                  steps: [
                    'Login ke internet banking Bank BNI Anda',
                    'Pilih menu Transaksi lalu klik menu Virtual Account Billing',
                    `Masukkan Nomor VA (${response.data.payment_code}) lalu pilih Rekening Debit`,
                    'Detail transaksi akan ditampilkan, pastikan data sudah sesuai',
                    'Masukkan respon key BNI appli 2',
                    'Transaksi sukses, simpan bukti transaksi Anda'
                  ]
                },
                {
                  title: 'ATM BNI',
                  steps: [
                    'Masukkan kartu Anda',
                    'Pilih Bahasa',
                    'Masukkan PIN ATM Anda',
                    'Kemudian, pilih Menu Lainnya',
                    'Pilih Transfer dan pilih jenis rekening yang akan digunakan (Contoh: Dari rekening Tabungan)',
                    `Pilih Virtual Account Billing. Masukkan Nomor VA (${response.data.payment_code})`,
                    'Tagihan yang harus dibayarkan akan muncul pada layar konfirmasi',
                    'Konfirmasi, apabila telah selesai, lanjutkan transaksi',
                    'Transaksi Anda telah selesai'
                  ]
                }
              ]

              this.instructionStep = instructionArry
            } else if (paymentChannelId === '12') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'Permata Virtual Account',
                iconBank: require('../assets/svg/icon-permata-bank.svg'),
                code: 'PERMATAVA',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                accountNumber: data.payment_code,
                transactionDueDate: this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL')
              }

              instructionArry = [
                {
                  title: 'Internet Banking',
                  steps: [
                    'Login ke internet banking Bank Permata Anda',
                    'Pilih menu Pembayaran lalu klik menu Pembayaran Tagihan',
                    'Pilih menu Virtual Account',
                    'Pilih Rekening Asal lalu centang Masukkan Daftar Tagihan Baru',
                    `Masukkan Nomor VA (${response.data.payment_code}) lalu klik Lanjutkan`,
                    'Detail transaksi akan ditampilkan, pastikan data sudah sesuai lalu klik Lanjutkan',
                    'Masukkan SMS Token Permata, lalu klik Lanjutkan',
                    'Transaksi sukses, simpan bukti transaksi Anda'
                  ]
                },
                {
                  title: 'ATM Permata',
                  steps: [
                    'Masukkan PIN',
                    'Pilih menu Transaksi Lainnya',
                    'Pilih menu Pembayaran',
                    'Pilih menu Pembayaran Lainnya',
                    'Pilih menu Virtual Account',
                    `Masukkan Nomor VA (${response.data.payment_code}) lalu tekan Benar`,
                    'Pilih rekening yang menjadi sumber dana yang akan didebet',
                    'Tekan Ya untuk konfirmasi transaksi'
                  ]
                }
              ]

              this.instructionStep = instructionArry
            } else if (paymentChannelId === '14') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'BRI Virtual Account',
                iconBank: require('../assets/svg/icon-bri-bank.svg'),
                code: 'BRIVA',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                accountNumber: data.payment_code,
                transactionDueDate: this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL')
              }

              instructionArry = [
                {
                  title: 'Internet Banking',
                  steps: [
                    'Login ke internet banking Bank BRI Anda',
                    'Pilih menu Pembayaran lalu klik menu BRIVA',
                    `Pilih rekening sumber dan masukkan Kode Bayar (${response.data.payment_code}) lalu klik Kirim`,
                    'Detail transaksi akan ditampilkan, pastikan data sudah sesuai',
                    'Masukkan nominal pembayaran',
                    'Klik Lanjutkan',
                    'Masukkan kata sandi ibanking lalu klik Request untuk mengirim m-PIN ke nomor HP Anda',
                    'Periksa HP Anda dan masukkan m-PIN yang diterima lalu klik Kirim',
                    'Transaksi sukses, simpan bukti transaksi Anda'
                  ]
                },
                {
                  title: 'Aplikasi BRImo',
                  steps: [
                    'Login ke aplikasi BRImo Anda',
                    'Pilih menu BRIVA',
                    `Pilih sumber dana dan masukkan Nomor Pembayaran (${response.data.payment_code}) lalu klik Lanjut`,
                    'Klik Lanjut',
                    'Detail transaksi akan ditampilkan, pastikan data sudah sesuai',
                    'Masukkan nominal pembayaran',
                    'Klik Lanjutkan',
                    'Klik Konfirmasi',
                    'Klik Lanjut',
                    'Masukkan kata sandi ibanking Anda',
                    'Klik Lanjut',
                    'Transaksi sukses, simpan bukti transaksi Anda'
                  ]
                },
                {
                  title: 'ATM BRI',
                  steps: [
                    'Lakukan pembayaran melalui ATM Bank BRI',
                    'Pilih menu Transaksi Lain > Pembayaran > Lainnya > Pilih BRIVA',
                    `Masukkan Nomor VA (${response.data.payment_code}) `,
                    'Pilih Ya untuk memproses pembayaran'
                  ]
                }
              ]

              this.instructionStep = instructionArry
            } else if (paymentChannelId === '15') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'Mandiri Virtual Account',
                iconBank: require('../assets/svg/icon-mandiri-bank.svg'),
                code: 'MANDIRIVA',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                accountNumber: data.payment_code,
                transactionDueDate: this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL')
              }

              instructionArry = [
                {
                  title: 'Internet Banking',
                  steps: [
                    'Login ke internet banking Anda',
                    'Pilih menu Pembayaran lalu klik menu Multipayment',
                    'Pilih Penyedia Jasa: WinPay',
                    `Masukkan Nomor VA (${response.data.payment_code})`,
                    `Masukkan Nominal ${formatPrice}`,
                    'Detail transaksi akan ditampilkan, pastikan data sudah sesuai',
                    'Klik tombol Konfirmasi',
                    'Periksa aplikasi Mandiri Online di ponsel Anda untuk menyelesaikan persetujuan transaksi',
                    'Transaksi sukses, simpan bukti transaksi Anda'
                  ]
                },
                {
                  title: 'ATM Mandiri',
                  steps: [
                    'Masukkan kartu ATM & isi PIN ATM Anda',
                    'Pilih menu Bayar/Beli lalu pilih menu Lainnya',
                    'Pilih lagi menu Lainnya',
                    'Pilih menu Multi Payment',
                    'Masukkan kode 88898 lalu tekan Benar',
                    `Masukkan Nomor VA (${response.data.payment_code})`,
                    'Detail transaksi akan ditampilkan, pastikan data sudah sesuai',
                    'Tekan 1 lalu tekan YA',
                    'Transaksi sukses, simpan bukti transaksi Anda'
                  ]
                }
              ]
              this.instructionStep = instructionArry
            } else if (paymentChannelId === '16') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'BCA Virtual Account',
                iconBank: require('../assets/svg/icon-bca-bank.svg'),
                code: 'BCAVA',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                accountNumber: data.payment_code,
                transactionDueDate: this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL')
              }

              instructionArry = [
                {
                  title: 'Internet Banking',
                  steps: [
                    'Login ke internet banking klikbca Anda',
                    'Pilih menu Transfer Dana lalu pilih Transfer ke BCA Virtual Account',
                    'Pilih Dari Rekening',
                    `Masukkan Nomor VA (${response.data.payment_code}) lalu klik Lanjutkan`,
                    'Detail transaksi akan ditampilkan, pastikan data sudah sesuai lalu masukkan respon Key BCA Appli 1',
                    'Klik Kirim',
                    'Transaksi sukses, simpan bukti transaksi Anda'
                  ]
                },
                {
                  title: 'Mobile Banking',
                  steps: [
                    'Login pada aplikasi BCA mobile',
                    'Pilih m-BCA masukkan kode akses m-BCA',
                    'Pilih m-Transfer',
                    'Pilih BCA Virtual Account',
                    `Masukkan Nomor VA (${response.data.payment_code}) lalu klik OK`,
                    'Konfirmasi no virtual account dan rekening pendebetan',
                    'Periksa kembalian rincian pembayaran anda, lalu klik Ya',
                    'Masukkan pin m-BCA',
                    'Pembayaran Selesai'
                  ]
                },
                {
                  title: 'ATM BCA',
                  steps: [
                    'Masukkan kartu ATM BCA & PIN',
                    'Pilih menu Transaksi Lainnya > Transfer > Ke Rekening BCA Virtual Account',
                    `Masukkan Nomor VA (${response.data.payment_code})`,
                    'Di halaman konfirmasi, pastikan detail pembayaran sudah sesuai seperti Nomor VA, Nama, Produk dan Total Tagihan',
                    'Jika sudah benar, klik Ya',
                    'Simpan struk transaksi sebagai bukti pembayaran'
                  ]
                }
              ]
              this.instructionStep = instructionArry
            } else if (paymentChannelId === '17') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'Alfamart',
                iconBank: require('../assets/svg/icon-alfamart.svg'),
                code: 'ALFAMART',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                accountNumber: data.payment_code,
                transactionDueDate: this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL')
              }

              instructionArry = [
                {
                  title: 'Pembayaran via ALFAMART',
                  steps: [
                    'Datang ke Alfamart',
                    'Sampaikan ke kasir ingin melakukan pembayaran Plasamall',
                    `Berikan kode bayar (${response.data.payment_code}) ke kasir`,
                    'Bayar sesuai jumlah yang diinfokan oleh kasir',
                    'Simpan struk bukti pembayaran Anda'
                  ]
                }
              ]
              this.instructionStep = instructionArry
            }else if (paymentChannelId === '18'){
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'QRIS',
                iconBank: require('../assets/svg/icon-qris.svg'),
                code: 'qris',
                gate: data.payment_gateway,
                accountNumber: data.payment_code,
                qrisUrl: data.payment_qr_url,
                transactionDueDate: this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL')
              }
            }
          } else if (data.payment_gateway == 'midtrans'){
            this.handleSnapPay(data.payment_token)
            if (paymentChannelId === '10') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'Gopay',
                iconBank: require('../assets/svg/gopay-seeklogo.com-5.svg'),
                code: 'gopay',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                accountNumber: null,
                paymentToken: data.payment_token,
                transactionDueDate: this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL')
              }
            }
          } else {
            if (paymentChannelId == '21') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'Transfer Bank Syariah Mandiri',
                iconBank: require('../assets/logo_bsi.png'),
                code: 'bsi_az_account',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                accountNumber: '3000202206',
                transactionDueDate: this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL'),
                ownerName: 'Yayasan Amazing Sedekah Indonesia'
              }
              this.transactionStatus = data.transaction_status
            } else if (paymentChannelId == '22') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'Transfer BRI',
                iconBank: require('../assets/svg/icon-bri-bank.svg'),
                code: 'bri_az_account',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                accountNumber: '033001000882566',
                ownerName: 'Yayasan Amazing Sedekah Indonesia',
                transactionDueDate: this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL')
              }
            } else if (paymentChannelId == '23') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'Transfer Mandiri',
                iconBank: require('../assets/svg/icon-mandiri-bank.svg'),
                code: 'mandiri_az_account',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                total: data.total,
                transactionCode: data.transaction_code,
                transactionDate: this.$moment(data.transaction_date)
                  .locale('id')
                  .format('LLLL'),
                accountNumber: '1270028092029',
                transactionDueDate: data.transaction_due_date ? this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL') : '-',
                transactionStatus: data.transaction_status,
                ownerName: 'Yayasan Amazing Sedekah Indonesia'
              }
            } else if (paymentChannelId === '02'){
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'Transfer CIMB Niaga Syariah',
                iconBank: require('../assets/svg/icon-cimb-syariah-bank.svg'),
                code: 'cimb_syariah_account',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                accountNumber: '762369272300',
                transactionDueDate: this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL'),
                ownerName: 'Yayasan Amazing Sedekah Indonesia'
              }
              this.transactionStatus = data.transaction_status
            } else if (paymentChannelId === '03') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'Transfer BCA',
                iconBank: require('../assets/svg/icon-bca-bank.svg'),
                code: 'bca_account',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                accountNumber: '5865321225',
                transactionDueDate: this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL'),
                ownerName: 'Nanu Utama'
              }
              this.transactionStatus = data.transaction_status
            } else if (paymentChannelId === '04') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'Transfer BRI',
                iconBank: require('../assets/svg/icon-bri-bank.svg'),
                code: 'bri_account',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                accountNumber: '7317-01-004937-50-0',
                transactionDueDate: this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL'),
                ownerName: 'Nanu Utama'
              }
              this.transactionStatus = data.transaction_status
            } else if (paymentChannelId === '05') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'Transfer Mandiri',
                iconBank: require('../assets/svg/icon-mandiri-bank.svg'),
                code: 'mandiri_account',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                accountNumber: '127-00-1063427-5',
                transactionDueDate: this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL'),
                ownerName: 'Nanu Utama'
              }
              this.transactionStatus = data.transaction_status
            }
          }

          this.loading = false
          // const total = response.data.total
        })
    },
    handleSnapPay(token) {
      this.$store.dispatch('midtrans/fetchSnap').then(response => {
        response.onload = function() {
          window.snap.pay(token, {
            // Optional
            onSuccess: function(result) {
              result
            },
            // Optional
            onPending: function(result) {
              result
              // return result
            },
            // Optional
            onError: function(result) {
              result
              // return result
            }
          })
        }
      })
    },
    handleOpenSnapPay(token){
      window.snap.pay(token, {
        // Optional
        onSuccess: function(result) {
          result
        },
        // Optional
        onPending: function(result) {
          result
          // return result
        },
        // Optional
        onError: function(result) {
          result
          // return result
        }
      })
    },
    handleTooltipCopy(value) {
      const tempInput = document.createElement('INPUT')
      document.body.appendChild(tempInput)
      tempInput.setAttribute('value', value)
      tempInput.select()
      tempInput.setSelectionRange(0, 99999)
      document.execCommand('copy')
      document.body.removeChild(tempInput)
      const tooltip = document.getElementById('tooltip-payment')
      tooltip.classList.toggle('active')
      setTimeout(() => {
        tooltip.classList.remove('active')
      }, 1500)
    },
    handleCopyValue(value) {
      const tempInput = document.createElement('INPUT')
      document.body.appendChild(tempInput)
      tempInput.setAttribute('value', value)
      tempInput.select()
      tempInput.setSelectionRange(0, 99999)
      document.execCommand('copy')
      document.body.removeChild(tempInput)
      const tooltip = document.getElementById('tooltip-value')
      tooltip.classList.toggle('active')
      setTimeout(() => {
        tooltip.classList.remove('active')
      }, 1500)
    },
    handleVerifyingBankTransfer() {
      swal.fire({
        title: '', // add html attribute if you want or remove
        text: 'Mohon Menunggu',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: function () {
          swal.showLoading()
        }
      })
      this.$http
        .post(
          `${process.env.VUE_APP_API_URL}donate/${this.$route.params.code}/verifying`
        )
        .then(response => {
          const data = response.data
          if (data.transaction_status === 'verifying') {
            swal.close()
            swal.hideLoading()
            swal.fire({
              icon: 'success',
              title: 'Berhasil',
              text: 'Pembayaran anda diterima',
              showConfirmButton: false
            })
            this.transactionStatus = data.transaction_status
          }
        })
    }
  }
}
</script>
